.right_box[data-v-62ffdfd5] {
  position: absolute;
  right: 19px;
  top: 13px;
}
.item_icon[data-v-62ffdfd5] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-62ffdfd5] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-62ffdfd5] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.el-select[data-v-62ffdfd5] {
  margin-left: 0px;
}
[data-v-62ffdfd5] .el-overlay {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.el_icon[data-v-62ffdfd5] {
  cursor: pointer;
  margin-top: 120px;
}
.btn_button_view[data-v-62ffdfd5] {
  display: flex;
  justify-content: center;
  width: 500px;
}
.btn_button_view el-button[data-v-62ffdfd5] {
  width: 70px;
  border: 2px solid #fff;
  background: black;
}
[data-v-62ffdfd5] textarea {
  min-height: 145px !important;
}
.dzz-pdf-dialog-content-container[data-v-62ffdfd5] {
  margin-top: -10px;
  margin-bottom: -10px;
}
.dzz-pdf-dialog-close[data-v-62ffdfd5] {
  position: absolute;
  right: -1px;
  top: 0;
  z-index: 1;
  cursor: pointer;
}
.dzz-pdf-btn-box[data-v-62ffdfd5] {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}